<template lang="html">
  <header id="header">

    <div class="header-content">
      <div class="header-menu">
        <b-navbar toggleable="lg" type="light" variant="light">
          <div class="container oversized-container">
            <b-navbar-brand to="/">
              <img src="public/images/logo.svg">
            </b-navbar-brand>

            <b-navbar-toggle target="nav-collapse"><i class="fal fa-bars"></i></b-navbar-toggle>

            <b-collapse id="nav-collapse" is-nav>
              <!-- <b-navbar-nav>
                <b-nav-item href="#">Link</b-nav-item>
              </b-navbar-nav> -->

              <!-- Right aligned nav items -->
              <b-navbar-nav class="ml-auto">
                <b-nav-item class="simple-item" to="/">Inicio</b-nav-item>
                <b-nav-item class="simple-item" to="/nosotros">Nosotros</b-nav-item>
                <!-- <b-nav-item class="simple-item" to="/productos">Productos</b-nav-item> -->
                <li class="nav-item simple-item">
                  <a class="nav-link" @click="isPActive = !isPActive">Productos <i class="fas fa-caret-down"></i></a>
                </li>
                <b-nav-item class="simple-item" to="/contacto">Contacto</b-nav-item>
                <!-- <li class="nav-item simple-item red-item">
                  <a class="nav-link" target="_blank" href="public/pdfs/catalogo.pdf"><i class="fas fa-book"></i> Catálogo</a>
                </li> -->
                <!-- <b-nav-item class="btn-item bg-cprimary" to="">Contacto</b-nav-item> -->
                <!-- <b-nav-item class="btn-item outline-csecondary demo" target="_blank" href="https://enclick.com.mx/demo#/m/2/7/home">Demo</b-nav-item> -->

                <!-- IOS & ANDROID -->
                <!-- <li class="nav-item networks-item">
                  <div class="nav-link">
                    <span>Disponible</span>
                    <a class="btn-network" target="_blank" href=""><i class="fab fa-apple"></i></a>
                    <a class="btn-network" target="_blank" href=""><i class="fab fa-google-play"></i></a>
                  </div>
                </li> -->
              </b-navbar-nav>
            </b-collapse>
          </div>
        </b-navbar>
      </div>

      <div class="heade-extra">
        <div class="container oversized-container">
          <div class="row">
            <div class="col-lg-6 col-info">
              <p>
                <span class="mr-3 txt-inf"><i class="fas fa-clock ic"></i> Lun - Vie: 9 - 19 hrs</span>
                <span class="txt-inf"><i class="fas fa-map-marker-alt ic"></i> Av. Juan Gil Preciado 7013-1, Nuevo México, Jal</span>
              </p>
            </div>

            <div class="col-lg-6 text-lg-right col-info">
              <span class="txt-inf"><i class="fas fa-phone-alt ic"></i> LLÁMANOS: (33) 2737 5123</span>
              <!-- <a class="ml-3" target="_blank" href="http://www.facebook.com/messagingservice"><i class="fab fa-facebook-f"></i></a> -->
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Products menu -->
    <div class="t-250 products-menu" v-bind:class="{ active: isPActive }">
      <div class="menu-bg" @click="isPActive = false"></div>

      <div class="tablist" role="tablist">
        <div class="t-250 box-title" @click="isPActive = false">
          <h4>PRODUCTOS</h4>
          <br>
          <h5>Categorías</h5>
          <i class="far fa-chevron-left icon"></i>
        </div>

        <b-card no-body class="mb-1" v-for="(category, pcatIndex) in categories" :key="pcatIndex">
          <b-card-header header-tag="header" role="tab">
            <router-link  class="btn-toggle-fake" :to="'/productos/'+$root._converToURL(category.name, category.id)">{{ category.name }}</router-link>
          </b-card-header>


        </b-card>
      </div>
    </div>
    <!--  -->

  </header>
</template>

<script>
export default {
  data() {
    return {
      isPActive: false,
      categories:[]

    }
  },

  methods: {
    getCategories(){
      axios.get(tools.url("/api/categories")).then((response)=>{
          this.categories = response.data;
        }).catch((error)=>{

        });
    },
  },

  watch: {
    $route (to, from){
      // Ocultar menu cada vez que cambie la ruta
      this.isPActive = false;
    }
  },
  mounted(){
      this.getCategories();
  }
}
</script>
