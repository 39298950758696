<template lang="html">
  <footer id="footer">
  	<section class="footer-section">
      <div class="container oversized-container">
        <div class="row">
          <div class="col-lg-9">
            <div class="row">
              <div class="col-sm-12 col-lg-4 col-footer">
                <h5>General</h5>

                <p class="mt-lg-1"><router-link class="link-footer" to="/">Inicio</router-link></p>
                <p class="f-w-300"><router-link class="link-footer" to="/nosotros">Nosotros</router-link></p>
                <p class="mt-lg-1"><router-link class="link-footer" to="/productos">Productos</router-link></p>
								<p class="mt-lg-1"><router-link class="link-footer" to="/contacto">Contacto</router-link></p>
              </div>

              <div class="col-sm-12 col-lg-8 col-footer">
                <!-- <h5>Nosotros</h5>

                <p class="p-md">
                  Somos una empresa sólida dedicada a la fabricación y comercialización que cuenta con cimientos basados en nuestros valores de operación y en la seguridad de los procesos que llevamos a cabo, ya que gracias a estos hemos mantenido un crecimiento e innovación constante de nuestros productos.
                </p> -->

								<!-- <p class="mt-3 copy-text">
                  Copyright {{ currentYear }} © MrBag
                </p> -->
              </div>
            </div>
          </div>


          <div class="col-lg-3 col-footer col-copyright align-self-center">
            <!-- <p class="mb-3">
              <router-link class="t-150 btn-network" to="/contacto">
                <span><i class="fal fa-envelope"></i></span>
              </router-link>
              <a class="t-150 btn-network" target="_blank" href="#">
                <span><i class="fab fa-instagram"></i></span>
              </a>
              <a class="t-150 btn-network" target="_blank" href="#">
                <span><i class="fab fa-tiktok"></i></span>
              </a>
							<a class="t-150 btn-network" target="_blank" href="http://www.facebook.com/messagingservice">
								<span><i class="fab fa-facebook-f"></i></span>
							</a>
              <router-link class="t-150 btn-network" to="/contacto">
                <span><i class="fas fa-envelope"></i></span>
              </router-link>
            </p> -->

            <p class="p-sm p-icon i-letter">
              atencionaclientes@mrbag.com.mx
            </p>
            <p class="mt-3 p-sm p-icon i-phone">
              (33) 2737 5123
            </p>
            <p class="mt-3 p-sm p-icon i-map">
              Av. Juan Gil Preciado 7013-1,<br />
              Marcelino García Barragán, 45134<br />
              Nuevo México, Jal
            </p>

            <p class="mt-3 copy-text">
              Copyright {{ currentYear }} © MrBag
            </p>
          </div>
  			</div>
      </div>
  	</section>
  </footer>
</template>

<script>
export default {
  data(){
    return{
      currentYear: 0,

      form: {
        email: null
      },
    }
  },

  methods:{
    makeToast(variant = null, msg, title) {
      this.$bvToast.toast(msg, {
        title: title,
        variant: variant,
        solid: true,
        toaster: 'b-toaster-top-right',
        appendToast: true
      });
    },

    onSubmit(evt) {
      evt.preventDefault();

        axios.post(tools.url("/api/newsletter"), this.form).then((response)=>{
          alert('Infomación guardada correctamente');
          this.form = {
            name: null,
            email: null,
            msg: null,
            check: null
          };
        }).catch( (error)=> {

        });

    },
  },

  beforeMount() {
    this.currentYear = new Date().getFullYear();
  }
}
</script>
